import Draggable from "react-draggable";
import { useRef } from "react";
import helpline from "../assets/icons/chat.png";

const WhatsAppHotline = () => {
	const dragStartPositionXYRef = useRef();

	return (
		<div
			style={{
				top: 20,
				zIndex: 1001,
				position: "fixed",
				width: "calc(100% - 40px)",
				maxWidth: "calc(430px - 40px)",
				height: "calc(100% - 40px)",
				maxHeight: "calc(100vh - 40px)",
				pointerEvents: "none",
				left: "50%",
				transform: "translate(-50%, 0)"
			}}
		>
			<Draggable
				style={{
					position: "absolute",
					zIndex: 1000,
					pointerEvents: "auto"
				}}
				bounds="parent"
				onStart={(_, data) => {
					dragStartPositionXYRef.current = { x: data.x, y: data.y };
				}}
				onStop={(_, data) => {
					const THRESHOLD = 2;
					const { x, y } = dragStartPositionXYRef.current ?? { x: 0, y: 0 };
					const wasDragged = Math.abs(data.x - x) > THRESHOLD && Math.abs(data.y - y) > THRESHOLD;
					if (!wasDragged) {
						window.open("https://wa.me/+60149700341", "_blank");
					}
				}}
			>
				<div
					style={{
						top: "50%",
						right: "-2%",
						position: "fixed",
						pointerEvents: "auto"
					}}
				>
					<img src={helpline} alt="whatsapp" style={{ objectFit: "contain", width: "65px" }} />
				</div>
			</Draggable>
		</div>
	);
};

export default WhatsAppHotline;
